import React from "react";
import Hero from "../Components/Homepage/Hero";
import Brief from "../Components/Homepage/Brief";
import Category from "../Components/Homepage/Category";
import Product from "../Resources/Product";
import ProductCategory from "../Components/Homepage/ProductCategory";
import Choose from "../Components/Homepage/Choose";
import BlogWidget from "../Components/Homepage/BlogWidget";
const Home = () => {
  return (
    <div className="Home">
      <Hero />
      <Brief />
      <Category />
      <div id="products">
        <ProductCategory />
      </div>
      <Choose />
      <BlogWidget />
    </div>
  );
};

export default Home;
