import React from "react";

import textdec from "../About/textdeco.png";
import chemfree from "../../Assets/values/chemical-free.webp";
import crueltyfree from "../../Assets/values/cruelty-free-1.webp";
import parabenfree from "../../Assets/values/paraben-free-1.webp";
import vegan from "../../Assets/values/vegan.webp";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";

export const Value = [
  {
    icon: chemfree,
    text: "Chemical-Free Elegance . Our skincare is chemical-free, using natural ingredients for effective, safe, and nourishing results. Pamper your skin with products that prioritize both effectiveness and long-term health.",
  },
  {
    icon: crueltyfree,
    text: "Cruelty-Free Beauty . We promise cruelty-free beauty. No animals are harmed in our products’ creation, ensuring your beauty regimen is kind and supportive of a world that values compassion.",
  },
  {
    icon: parabenfree,
    text: "Paraben Free Perfection.  Choose paraben-free skincare for a perfect balance of science and nature. Prioritize your skin’s well-being without compromising on results. It’s the path to skincare perfection.",
  },
  {
    icon: vegan,
    text: "Vegan-Friendly Skincare . Our skincare is vegan-friendly, reflecting our commitment to animals and the environment. Free from animal-derived ingredients, it’s a compassionate and sustainable approach to beauty that aligns with your values.",
  },
];

const Values = () => {
  const list = [
    {
      icon: chemfree,
      text: "Chemical-Free Elegance . Our skincare is chemical-free, using natural ingredients for effective, safe, and nourishing results. Pamper your skin with products that prioritize both effectiveness and long-term health.",
    },
    {
      icon: crueltyfree,
      text: "Cruelty-Free Beauty . We promise cruelty-free beauty. No animals are harmed in our products’ creation, ensuring your beauty regimen is kind and supportive of a world that values compassion.",
    },
    {
      icon: parabenfree,
      text: "Paraben Free Perfection.  Choose paraben-free skincare for a perfect balance of science and nature. Prioritize your skin’s well-being without compromising on results. It’s the path to skincare perfection.",
    },
    {
      icon: vegan,
      text: "Vegan-Friendly Skincare . Our skincare is vegan-friendly, reflecting our commitment to animals and the environment. Free from animal-derived ingredients, it’s a compassionate and sustainable approach to beauty that aligns with your values.",
    },
  ];
  const settings = {
    className: "center border-2 border-black text-center overflow-x-scroll w-auto flex",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,

    speed: 500,
    accessibility: true,
    arrows: true,
    dotsClass: "slick-dots",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 4,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="my-[100px]">
      <div className="text-center">
        <h1 className="text-[32px] my-6 font-medium">Our Values</h1>

        <div className="slider-container">
          <Slider {...settings} className="">
            {Value.map((item, index) => (
              <div key={index} className="w-full">
                <div className="flex flex-col items-center justify-between ">
                  <div className="h-[50%] w-[50%] ">
                    <img src={item.icon} alt="imgg" className="object-fit h-full w-full" />
                  </div>

                  <div className="text-center my-10 w-[80%]">
                    <p className="text-sm font-light">{item.text}</p>
                    <p className="mobile:text-[14px]">{item.submain}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="text-center my-[100px] ">
        <p className="text-[32px] font-medium   leading-[5rem]">Our Story </p>
        <div className="leading-10 text-[16px] mobile:text-[14px]  text-justify">
          <div className="my-4">
            <p className="text-center">
              Hi, thanks for dropping by, I’m Rola Awosanmi the CEO of Nifinspired-Skincare, I
              started formulating skincare products in a quest to find a lasting solution to solve
              my own skin problems(Acne, hyperpigmentation/sensitive skin) and that of my youngest
              son’s(dry skin) at the time.
            </p>
            <div className="flex justify-center ">
              <img src={textdec} className="h-10" />
            </div>
          </div>
          <div className="my-4">
            <p className="text-center">
              As a teenager I suffered from severe acne, sensitive skin and hyperpigmentation,
              nothing I used on my skin ever seems to work, its either the products were too harsh,
              making my skin condition worse, or I was not using the right product that suits my
              skin type, being young and naïve I did not know any better. My second son on the other
              hand has really dry skin, he usually gets prescription from the GP for his dry skin,
              he was only two at the time, this did not seem to work either, we use shampoos for his
              dry scalp which was always flaky, most time the school would ask me not to bring him
              in, because of the other kids, as a mum this made me sad.
            </p>
            <div className="flex justify-center ">
              <img src={textdec} className="h-10" />
            </div>
          </div>
          <div className="my-4">
            <p className="text-center">
              Doctor confirmed its just dry skin and not contagious. This made me even more
              determined to find a lasting solution. “There has to be a way out I thought” I started
              doing online research, on skincare, took some online courses, in skincare formulation,
              I must confess it was kind of therapeutic for me. Having a sensitive skin myself, I
              knew using harsh chemicals in my product formulations was a no, no, I needed to make
              the products as natural as possible Bingo! I started using hard butters (shea butter,
              cocoa butter) essential oils botanical oils and extracts, locally sourced organic raw
              ingredients. I would make my own soap, which are SLS free, meaning they were free from
              salt which can be too harsh on the skin and mostly used in skincare product, body
              balms and oils, for years myself, families and friends experimented my products and
              the results and feedbacks were amazing.
            </p>
            <div className="flex justify-center ">
              <img src={textdec} className="h-10" />
            </div>
          </div>
          <div className="my-4">
            <p className="text-center">
              This gave me so much satisfaction, most especially Nifemi’s skin, I was thrilled,his
              dry skin disappeared only because he was using the right product for his skin and also
              no harsh chemicals involved, just pure nature. I realised a lot of skincare over the
              counter, are not 100% safe, natural nor designed to suit women of colour. I knew I had
              to make a difference. With so much persistence, from my partner, who has so much faith
              in my products, it was time to make a difference, in the skincare community. Getting
              my certifications, and my products registered by the relevant bodies. Today, we are
              more aware than ever about how important it is, to look after our skin and make sure
              the products we use are free from toxic products like SLS, parabens, hydroquinone,
              (used to remove melasma and hyperpigmentation from the skin) and the likes of them,
              making our products one of the safest out there.
            </p>
            <div className="flex justify-center ">
              <img src={textdec} className="h-10" />
            </div>
          </div>
          <div className="my-4">
            <p className="text-center">
              I decided to turn my passion into business, to help and also relate with people of
              like minds, in encouraging wellness and healthy skin care. Our products are sourced
              locally and organically from the farm to provide your skin with all the benefits it
              deserves. NifInspired-Skincare, involves using botanical extracts, oils and essential
              oils gently extracted from plants, seeds, flowers that are organically sourced
              directly from the farm. The products are vegan friendly, cruelty free,100% organic,
              Sulfate free, paraben free and these are also our values which tells you as a customer
              what goes into your products. NifInspired-Skincare exists to create safe products,
              causing no harm, our purpose is to stand out from the crowd, creating healthy products
              that are well formulated, using safe and effective ingredients that have aromatherapy,
              anti-inflammatory, anti-bacteria, nourishing and healing properties that the skin
              needs,
            </p>
            <div className="flex justify-center ">
              <img src={textdec} className="h-10" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
