import React from "react";

const Delivery = () => {
  return (
    <div>
      <div className="my-14">
        <p className="text-[46px]">Ordering & Delivery</p>
      </div>
      <div>
        <p>
          Thank you for choosing our organic skincare products! To place an order via mail and make
          a manual payment, follow these simple steps:
        </p>
      </div>

      <div className="m-5 flex ">
        <ol>
          <li className="my-5">
            <span className="font-semibold">Product Selection:</span> Browse our website or product
            catalog to choose the organic skincare products you’d like to purchase. Make a note of
            the product names, sizes, and quantities you wish to order.
          </li>
          <li className="my-5">
            {" "}
            <span className="font-semibold">Order Details:</span> Write down your complete order
            details, including the product names, sizes, quantities, and their corresponding prices.
          </li>
          <li className="my-5">
            <span className="font-semibold">Personal Information: </span>
            Include your full name, shipping address, email address, and contact number on a piece
            of paper. Double-check that all information is accurate to ensure smooth processing.
          </li>
          <li className="my-5">
            <span className="font-semibold">Payment Calculation:</span> Calculate the total cost of
            your order by adding up the prices of the selected products. Don’t forget to include any
            applicable shipping or handling fees.
          </li>
          <li className="my-5">
            <span className="font-semibold">Payment Method:</span> We currently accept manual
            payments through checks or money orders. Make the payment payable to [Company Name].
            Please do not send cash through the mail.
          </li>
          <li className="my-5">
            <span className="font-semibold">Payment Amount: </span>
            Write the total payment amount clearly on the check or money order. Ensure that it
            matches the calculated total cost of your order.
          </li>
          <li className="my-5">
            <span className="font-semibold">Note and Reference:</span> If you have any special
            requests, product preferences, or a reference number, please include this information on
            a separate note.
          </li>
          <li className="my-5">
            <span className="font-semibold">Packaging and Mailing:</span> Place the note, payment,
            and order details in an envelope. You can then send the envelope to our mailing address:
            <p>[Name] [Address Line 1] [Address Line 2 (if applicable)] [City, State, ZIP Code]</p>
          </li>
          <li className="my-5">
            <span className="font-semibold">Confirmation Email: </span> After we receive your
            payment and order details, we will send you a confirmation email to acknowledge your
            order. This email will include information about the processing and estimated delivery
            time.
          </li>
          <li className="my-5">
            <span className="font-semibold">Order Fulfillment:</span> Once your payment is
            processed, we will carefully prepare your order and arrange for its shipment. You will
            receive a shipping notification email with tracking information when your package is on
            its way.
          </li>
        </ol>
      </div>

      <p>
        If you have any questions or need assistance throughout the ordering process, feel free to
        contact our customer service team at [Customer Service Email] or [Customer Service Phone
        Number]. Thank you for choosing our organic skincare products. We appreciate your business
        and look forward to providing you with high-quality products that promote natural beauty and
        wellness.
      </p>

      <div className="my-10">
        <p className=" text-[46px]">Payment Method: Manual Payment</p>
        <ul>
          <li className="my-4">Bank Name:</li>
          <li className="my-4">Account Holder Name:</li>
          <li className="my-4">Account Number:</li>
          <li className="my-4">Routing Number (if applicable):</li>
          <li className="my-4">Any additional payment instructions:</li>
        </ul>
      </div>
    </div>
  );
};

export default Delivery;
