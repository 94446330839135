import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import RootLayout from "./Resources/RootLayout";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Login from "./Pages/Login";
import Forgotpass from "./Pages/Forgotpass";
import Recovery from "./Components/Recovery";
import Register from "./Pages/Register";
import EachProduct from "./Pages/EachProduct";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Pay from "./Payments/Pay";

import CategoryDescription from "./Pages/CategoryDescription";
import BlogStory from "./Pages/BlogStory";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import Usage from "./Pages/Usage";
import Delivery from "./Pages/Delivery";

//for the router we are using the React-Router-Dom version 6.4
//check treello infospace for the link to the documentation and Link to a Tutorial video
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/** this route is for the all the pages that would contain the Navigation */}
      <Route path="/" element={<RootLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/category/:categoryId" element={<CategoryDescription />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Blog/:blogId/" element={<BlogStory />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product/:productId" element={<EachProduct />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/usage" element={<Usage />} />
        <Route path="/delivery" element={<Delivery />} />
      </Route>

      {/** Routes that are not goin to contain the navigation */}
      <Route path="/payment" element={<Pay />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgotpass />} />
      <Route path="/password-reset" element={<Recovery />} />
    </Route>
  )
);

//for the tailwind CSS there is already a set default font
//that would be used for this project "poppins" and also color "accent"

function App() {
  return <RouterProvider router={router} />;
}

export default App;
