import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TrashOutline } from "heroicons-react";

const Cart = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useOutletContext();
  const [count, setCount] = useState(1);

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("nifInspiredUser"));
    // const token = localStorage.getItem("nifInspiredToken");
    // if (token && user) {

    // } else {
    //   localStorage.setItem("accessedProduct", true);
    //   window.location.pathname = "/login";
    // }
    // localStorage.removeItem("accessedProduct");
    let cartProduct = localStorage.getItem("cart");
    if (cartProduct) {
      setProducts(JSON.parse(cartProduct));
    } else {
      setProducts([]);
    }
  }, []);

  function roundToDecimalPlaces(number, decimalPlaces) {
    return parseFloat(number.toFixed(decimalPlaces));
  }

  const calcTotal = () => {
    let total = 0;
    products.map((item) => {
      total += item.product.price * item.quantity;
    });
    return total;
  };

  const addToItem = (item) => {
    let currentItem = products.find((i) => i.product.id === item.product.id);
    if (currentItem) {
      currentItem.quantity += 1;
      setProducts([...products]);
    }
    localStorage.setItem("cart", JSON.stringify(products));
  };

  const removeFromItem = (item) => {
    let currentItem = products.find((i) => i.product.id === item.product.id);
    if (currentItem.quantity > 1) {
      currentItem.quantity -= 1;
      setProducts([...products]);
    }
    localStorage.setItem("cart", JSON.stringify(products));
  };

  const deleteItem = (item) => {
    let currentItem = products.find((i) => i.product.id === item.product.id);
    if (currentItem) {
      let index = products.indexOf(currentItem);
      products.splice(index, 1);
      setProducts([...products]);
    }
    localStorage.setItem("cart", JSON.stringify(products));
  };
  return (
    <>
      <div className="w-full cflexss gap-[45px] mb-[40px] mt-[50px]">
        <div className="cflexss text-[25px] mobile:[20px] text-accent">
          <p className="text-[72px] mobile:text-[55px]">
            Cart [{products.length}]
          </p>
          <p>your cart items</p>
        </div>
        <div className="w-full flexbs gap-[20px] smtab:flex-wrap smtab:gap-[30px] mobile:flex-wrap mobile:gap-[30px]">
          <div className="cflexss gap-[60px]">
            {products.length > 0 ? (
              products?.map((item, index) => {
                return (
                  <>
                    <div className="w-full flexss bg-[#F5F5F5] border-b-[3px] border-black h-[315px] rounded-[10px] mobile:flex-wrap mobile:h-auto">
                      <img
                        src={item.product.image}
                        alt={`${item.product.image}-image`}
                        className="w-[274px] h-full mobile:h-auto mobile:w-full rounded-[10px] object-cover"
                      />
                      <div className="cflexbs w-[600px] gap-[10px] h-full py-[20px] px-[30px] flex-shrink smtab:w-[500px] mobile:w-full">
                        <div className="cflexss w-full gap-[15px]">
                          <p className="text-[18px]">
                            <span className="block font-semibold text-[32px]">
                              {item.product.category}
                            </span>{" "}
                            {item.product.name}
                          </p>
                          <p className="font-bold text-[32px]">
                            ${item.product.price}
                          </p>
                        </div>
                        <div className="w-full flexbs">
                          <div className="flexss">
                            <div
                              className="px-[15px] py-[10px] border-[#000000]/60 rounded-l-[10px] border-[2px] border-r-transparent cursor-pointer"
                              onClick={() => {
                                removeFromItem(item);
                              }}
                            >
                              -
                            </div>
                            <div className="px-[15px] py-[10px] border-[#000000]/60 border-[2px]">
                              {item.quantity}
                            </div>
                            <div
                              className="px-[15px] py-[10px] border-[#000000]/60 rounded-r-[10px] border-[2px] border-l-transparent cursor-pointer"
                              onClick={() => {
                                addToItem(item);
                              }}
                            >
                              +
                            </div>
                          </div>
                          <TrashOutline
                            size="32px"
                            className="cursor-pointer"
                            onClick={() => {
                              deleteItem(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div className="w-full flexmm text-[32px]">
                  <p>No Item Saved To Cart</p>
                </div>
              </>
            )}
          </div>
          <div className="rounded-[25px] px-[25px] w-[480px] bg-[#F5F5F5] h-[600px] cflexss gap-[16px] flex-shrink">
            <div className="w-full cflexss py-[25px] gap-[30px] border-b-[2px] border-black">
              <p className="font-semibold text-[32px]">Order Summary</p>
              <div className="h-full w-full flexbs text-[24px] gap-[10px]">
                <div className="w-full cflexss gap-[6px]">
                  <p>Sub Total</p>
                  <p>Discount</p>
                  <p>Shipping</p>
                  <p className="pt-[20px]">Total</p>
                </div>
                <div className="w-[120px] cflexss gap-[6px]">
                  <p className="font-bold">
                    ${roundToDecimalPlaces(calcTotal(), 2)}
                  </p>
                  <p className="font-bold">$0</p>
                  <p className="font-bold">$3.00</p>
                  <p className="font-bold pt-[20px]">
                    ${roundToDecimalPlaces(calcTotal() + 3, 2)}
                  </p>
                </div>
              </div>
            </div>
            <button
              className="w-full outline-none border-none rounded-full bg-accent py-[16px] px-[25px] text-white font-bold text-[18px] flexmm"
              onClick={() => {
                navigate("/payment");
              }}
            >
              <p>Proceed to checkout</p>
            </button>
            <p className="w-full text-center text-accent text-[16px] pt-[10px] cursor-pointer">
              click here to view delivery information
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
