import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/prev.svg'
import { FiMail } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";import { IconContext } from 'react-icons';
import { NavData } from './RootLayout';
import { FaFacebookSquare, FaYoutube} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

export const socials =[
    {
        logo:<FaFacebookSquare />,
        path:'/'
    },

    {
        logo:<AiFillInstagram />,
        path:'/'
    },

    {
        logo:<FaYoutube />,
        path:'/'
    }
    
]
const Pagefoot = () => {
  return (
    <div className='h-[500px] bg-[#F1EDEE] mobile:px-[10px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px] py-10 flex flex-col justify-between' >
{/**the logo for the footer */}
      <div className=' h-'>
        <Link to ="/">
            <img src={logo} alt="logo" className='h-[70px]'/>
        </Link>
      </div>
{/**Address & email */}
    <div className='font-poppins flex items-center justify-between my-9 mobile:flex-col smtab:flex-col    '>
        <div>
            <IconContext.Provider value ={{size:"20px"}}> 
                <div className=''>
                    <div className='flex items-center my-5'>
                        <FiMail />
                        <p className='mx-3 '><a href="mailto:support@making-organic-cool.co.uk" className='font-medium'>support@making-organic-cool.co.uk</a></p>
                    </div>
                    <div className='flex items-start font-medium'>
                        <MdOutlineLocationOn />
                        <p className='mx-3'>Chapman Drive<br/>
                        Coventry, Warwickshire CV65HT<br/>
                        United Kingdom</p>
                    </div>
                </div>
            </IconContext.Provider>
        </div>


        <ul className='flex items-center justify-between w-2/4 mobile:w-full mobile my-5 smtab:w-full'>
            {NavData.map((list, index) =>(
                <li key={index} to={list.path}>
                    {list.title}
                </li>
            ))}
        </ul>
    </div>
    
    <div className='flex justify-between items-center '>
        <p>&copy; 2024 NifInspired</p>
        
        <ul className='flex items-center justify-between w-40'>
        <IconContext.Provider value={{size:"30px"}}>
          {socials.map((handle, index) => (
            <li key={index} to={handle.path}>
                {handle.logo}
            </li>
          ))}
          </IconContext.Provider>
        </ul>
    </div>

    </div>
  )
}

export default Pagefoot
