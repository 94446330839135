import React from 'react'
import Banner from '../Components/About/Banner'
import Values from '../Components/About/Values'
import Motivation from '../Components/About/Motivation'
import Counter from '../Components/About/Counter'

const About = () => {
  return (
    <div className='px-5'>
      <Banner />
      <Values />
      <Motivation />
      <Counter />
    </div>
  )
}

export default About