import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";
import { IconContext } from "react-icons";
import Button from "../Resources/Button";
import { useNavigate } from "react-router-dom";
import AddToCart from "../PopUp/AddToCart";

const Product = ({ categoryTitle, span, item, setRefresh, refresh = "" }) => {
  const prodData = item || [];
  const [cart, setCart] = useState(false);
  const [setProducts] = useOutletContext();
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col mobile:flex-wrap smtab:w-[100%]">
      {cart && <AddToCart setCart={setCart} product={product} setProducts={setProducts} />}
      <div className="flex items-center justify-between mb-6">
        <p className="font-bold text-[24px] capitalize">
          {categoryTitle}{" "}
          {span && (
            <span className="text-accent leading-5 font-poppins">{span}</span>
          )}
        </p>
        <IconContext.Provider value={{ size: "20px" }}>
          <Button name={<IoArrowForward />} />
        </IconContext.Provider>
      </div>

      <div className="flex items-center justify-start flex-wrap gap-[3rem] smtab:gap-1 ">
        {prodData?.map((dataItems) => (
          <div
            key={dataItems?.uuid}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/product/${dataItems.id}`);
              if (refresh !== null) {
                setRefresh(!refresh);
              }
            }}
            // to={`/product/${dataItems.id}`}
            className="shadow-sm hover:shadow-xl p-3 w-boxed relative rounded-xl h-high mobile:h-mhigh m-1 mobile:p-2 smtab:w-[45%] smtab:px-1 cursor-pointer mobile:w-full"
          >
            <div className="rounded-xl h-boxed overflow-hidden mobile:h-mboxed">
              <img
                className="w-[100%] h-[100%] object-contain"
                src={dataItems?.image}
                alt="product"
              />
            </div>
            {/**the product content */}
            <div className="flex flex-col ">
              <div className="flex items-center justify-between my-3 font-medium text-[12px ]">
                {Array.isArray(dataItems?.category) ? (
                  <div className="flex items-center lowercase gap-2 p-1 rounded-xl">
                    {dataItems?.category?.map((catItem, index) => (
                      <p className="text-accent border p-1 px-2 text-[11px] rounded-xl" key={index}>
                        {catItem}
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="text-accent border p-1 px-2 text-[11px] rounded-xl lowercase">
                    {dataItems.category}
                  </p>
                )}
                <p>£{dataItems?.price}</p>
              </div>

              <div className="cflexss w-full">
                <p className="text-[18px] font-semibold text-wrap mobile:text-[14px] line-clamp-1">
                  {dataItems?.name}
                </p>
                <p className="text-[14px] font-medium text-wrap mobile:text-[14px] line-clamp-1">
                  {dataItems?.description}
                </p>
              </div>

              <div className="absolute bottom-5 mt-[100px] flex items-center left-0 right-0 justify-between px-3 mobile:flex-wrap">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setProduct(dataItems);
                    setCart(true);
                  }}
                  name="Buy"
                  class=" border border-accent px-5 py-1  rounded-2xl hover:bg-accent hover:text-white text-sm transition-all  "
                />

                <div className="underline text-[12px]">Learn more</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
