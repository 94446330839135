import React from 'react'
import NumberCounter from 'number-counter';
import { RiStarSmileFill } from 'react-icons/ri';
import { IconContext } from 'react-icons';

const Counter = () => {
  return (
    <div className='my-10 flex items-center justify-between mobile:flex-col smtab:flex-col'>
        <div>
            <p className='text-[32px] mobile:my-10'>We've got </p>
        </div>

        <div className='flex flex-col items-center '>
            <IconContext.Provider value={{size:"70px", color:"#066B63"}}>
                <RiStarSmileFill />
            </IconContext.Provider>
            <NumberCounter end={750} delay={30} className='text-[102px] text-accent'/>
            <p className='text-[32px] text-center'>Happy Customers</p>

        </div>

        <div>
            <p className='text-[32px] '>and counting</p>
        </div>
    </div>
  )
}

export default Counter
