import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { fetchCategoryProducts, fetchAllProducts, fetchCategories } from "../Services/request";
import Product from "../Resources/Product";
import Category from "../Components/Homepage/Category";
import haircare from "../Assets/haircare.jpg";
import SlidingBanner from "../Resources/SlidingBanner";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const Shop = () => {
  const top = useRef(null);
  // const { title, bgImage, bgDescrip, Description, products } = productCategory;

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);
  let categoryProduct = [];

  const fetchProducts = async () => {
    scrollToRef(top);
    let products = await fetchAllProducts();
    setProducts(products);
    let categories = await fetchCategories();
    setCategories(categories);
    if (categories) {
      for (let i = 0; i < categories.length; i++) {
        let data = await fetchCategoryProducts(categories[i].name);
        categoryProduct.push({
          name: categories[i].name,
          products: data,
        });
      }
    }
    setCategoryProducts(categoryProduct);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <div className="font-poppins" ref={top}>
      <div className="mt-[100px] smtab:px-[10px] mobile:px-[10px]">
        <div className=" text-center my-8 font-poppins">
          <h1 className="text-[5rem] leading-15 mobile:text-[3rem] capitalize">Our Products</h1>
          {/* <p className="mobile:text-[14px]">
            Welcome to NifInspired , your ultimate destination for luxurious,
            organic skincare products. Our journey began with a passion for
            harnessing the power of nature to nurture your skin.
          </p> */}
        </div>
        <div className="w-full h-[500px] mobile:h-auto rounded-xl">
          <SlidingBanner />
        </div>
        <div className="w-full flexmm smtab:px-[10px] mobile:px-[10px]">
          <Category />
        </div>
      </div>

      <div className="my-[150px] smtab:px-[10px] mobile:px-[10px]">
        <Product item={products} categoryTitle="For" span="You" refresh={null} />
      </div>

      <div className="w-full cflexss gap-[90px] smtab:px-[10px] mobile:px-[10px] flex-wrap">
        {categoryProducts.length > 0 && (
          <>
            {categoryProducts.map((product, index) => {
              return (
                <>
                  <Product
                    item={product.products}
                    categoryTitle={product.name}
                    refresh={null}
                    key={product.name}
                  />
                </>
              );
            })}
            <div className="w-full cflexmm gap-[60px] mb-[60px]">
              <p className="text-[32px] font-bold">Services to help you shop</p>
              <div className="w-full flexbm gap-[30px] smtab:flex-wrap mobile:flex-wrap mobile:justify-center smtab:justify-center">
                {/* <Link
                  to="/faq "
                  className="relative w-[370px] h-[485px] flex-shrink cursor-pointer"
                >
                  <img
                    src={haircare}
                    alt="service-image"
                    className="w-full h-full object-cover rounded-[15px]"
                  />
                  <div className="absolute bottom-0 h-[140px] text-[24px] text-white font-medium left-0 w-full rounded-b-[15px] flexss bg-[#066B63] px-[20px] py-[25px]">
                    Frequently Asked Questions
                  </div>
                </Link> */}
                <Link
                  to="/usage"
                  className="relative w-full h-[485px] flex-shrink cursor-pointer hover:w-[250%] transition duration-1000 "
                >
                  <img
                    src={haircare}
                    alt="service-image"
                    className="w-full h-full object-cover rounded-[15px]"
                  />
                  <div className="absolute bottom-0 h-[140px] text-[24px] text-white font-medium left-0 w-full rounded-b-[15px] flexss bg-[#066B63] px-[20px] py-[25px]">
                    Usage instructions
                  </div>
                </Link>
                <Link
                  to="/delivery"
                  className="relative w-full h-[485px] flex-shrink cursor-pointer hover:w-[250%] transition duration-1000"
                >
                  <img
                    src={haircare}
                    alt="service-image"
                    className="w-full h-full object-cover rounded-[15px]"
                  />
                  <div className="absolute bottom-0 h-[140px] text-[24px] text-white font-medium left-0 w-full rounded-b-[15px] flexss bg-[#066B63] px-[20px] py-[25px]">
                    Ordering & Delivering
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Shop;
