import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCategories } from "../../Services/request";

const Category = () => {
  const [categories, setCategories] = useState([]);

  const fetchProductCategories = async () => {
    let data = await fetchCategories();
    setCategories(data);
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  return (
    <div className="w-full my-[100px]">
      <div className="flex items-center justify-between">
        <p className="font-bold text-[24px]">Product Category</p>
      </div>

      <div className="w-full justify-between flex gap-[30px] items-center my-[50px] mobile:flex-col smtab:flex-wrap smtab:justify-center mobile:gap-2 ">
        {categories?.map((items) => (
          <Link
            key={items.id}
            to={`/category/${items.name}`}
            className="group relative h-[300px] w-[400px] my-3 overflow-hidden rounded-xl flex items-center justify-center bg-accent  hover:bg-gradient-to-b hover:from-white/0 hover:to-accent/70 hover:bg-transparent transition-all smtab:mx-[20px] Tablet:mx-[10px] mobile:w-[100%] mobile:mx-0 smtab:w-full mobile:h-[200px] mobile:my-0"
          >
            <img
              src={items.image}
              alt="images"
              className="object-cover w-full h-full z-[-100] right-50 object-right"
            />
            <div className="absolute top-50% left-50%  cflexmm gap-[10px] text-center group-hover:bottom-5 hover:transition hover:duration-1000">
              <h1 className="text-[56px] font-bold text-white capitalize leading-none">
                {items.name}
              </h1>
              <p className="text-white">Nifinspired</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
