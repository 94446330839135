import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Button from "../../Resources/Button";
import SlidingBanner from "../../Resources/SlidingBanner";
import leaf from "../../Assets/green_leaves_logo.jpg";

import { IoArrowDown, IoWaterOutline } from "react-icons/io5";
import { IoLeafOutline } from "react-icons/io5";
import { IoSunnyOutline } from "react-icons/io5";
import { MdSunnySnowing } from "react-icons/md";
import { LuWaves } from "react-icons/lu";
import { IconContext } from "react-icons";
import { HashLink } from "react-router-hash-link";
const Hero = () => {
  const skinType = [
    {
      icon: <IoWaterOutline />,
      title: "Oily skin",
      text: "Oil free products to clean and open pores",
    },
    {
      icon: <IoLeafOutline />,
      title: "Sensitive skin",
      text: "Skincare for dry sensitive skin",
    },
    {
      icon: <IoSunnyOutline />,
      title: "  Dry Skin",
      text: "Effect relief and moisturising",
    },
    {
      icon: <MdSunnySnowing />,
      title: "Combination Skin",
      text: "Specialist products to tackle all skin conditions",
    },
    {
      icon: <LuWaves />,
      title: "Mature Skin",
      text: "Toning, Exoliating misturising",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="relative font-poppins h-[100vh] flex items-center justify- w-full mobile:h-[50vh] smtab:h-[60vh]">
      <div className="flex flex-col items-center justify-center  w-full gap-0">
        {/**the heroa section content */}
        <div className=" text-center">
          <p className="text-[72px] my-[5px] mobile:text-[42px] smtab:text-[32px] Tablet:text-[42px] ">
            Quality Skincare made with care
          </p>

          <p className="text-[18px] mobile:text-[16px] large:text-[18px] ">
            Transform your skincare routine and reveal your most radiant self with us today.
            <br />
            Shop Now & Experience the Difference
          </p>
        </div>
        <div className="flex items-center flex-col">
          <Button
            name="Browse our Collection"
            onClick={() => navigate("/shop")}
            class="my-[20px] px-9  mobile:px-6 py-4 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all text-[14px] hover:bg-white hover:text-accent border-2 hover:border-2 border-accent shadow-button"
          />
          <HashLink
            smooth
            to="#products"
            className="flex items-center cursor-pointer hover:font-bold"
          >
            <p className="mx-5">Buy now</p>
            <IconContext.Provider value={{ size: "20px" }}>
              <IoArrowDown />
            </IconContext.Provider>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;
