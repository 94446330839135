import React, { useState, useEffect } from "react";
import Product from "../../Resources/Product";
import { itemData } from "../../Resources/ProductData";
import { fetchAllProducts } from "../../Services/request";
import SlidingBanner from "../../Resources/SlidingBanner";
import { IoWaterOutline } from "react-icons/io5";
import { IoLeafOutline } from "react-icons/io5";
import { IoSunnyOutline } from "react-icons/io5";
import { MdSunnySnowing } from "react-icons/md";
import { LuWaves } from "react-icons/lu";
import { IconContext } from "react-icons";

const ProductCategory = () => {
  const skinType = [
    {
      icon: <IoWaterOutline />,
      title: "Oily skin",
      text: "Oil free products to clean and open pores",
    },
    {
      icon: <IoLeafOutline />,
      title: "Sensitive skin",
      text: "Skincare for dry sensitive skin",
    },
    {
      icon: <IoSunnyOutline />,
      title: "  Dry Skin",
      text: "Effect relief and moisturising",
    },
    {
      icon: <MdSunnySnowing />,
      title: "Combination Skin",
      text: "Specialist products to tackle all skin conditions",
    },
    {
      icon: <LuWaves />,
      title: "Mature Skin",
      text: "Toning, Exoliating misturising",
    },
  ];

  const categoryArray = itemData;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    let nifInspiredToken = localStorage.getItem("nifInspiredToken");
    setLoading(true);
    let data = await fetchAllProducts(nifInspiredToken);
    setLoading(false);
    setProducts(data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <div>
      <div>
        <Product item={products} categoryTitle="New Products" refresh={null} />
      </div>
      <div className="h-[full] w-full bg-cover my-[100px]">
        <div>
          <div className="my-10">
            <p className="text-[1.2rem] text-center font-semibold leading-loose">
              Always Choose your Skin
            </p>
            <p className="text-[1rem] text-center">
              Shop by skin condition and discover more about the process behind creating specialist
              skincare products{" "}
            </p>
          </div>
          <IconContext.Provider value={{ size: "50px", color: "066B63" }}>
            <div className="flex justify-between gap-10 mobile:gap-0 mobile: mobile:flex-wrap mobile:justify-center smtab:flex-wrap">
              {skinType?.map((type, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center w-[50%] smtab:w-[45%]"
                >
                  <div>{type.icon}</div>
                  <div className="text-center my-10">
                    <p className="text-[1rem] font-semibold mobile:text-[11px] smtab:text-[12px]">
                      {type.title}
                    </p>
                    <p className="mobile:text-[11px] smtab:text-[11px]">{type.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </IconContext.Provider>
        </div>
        <div className="h-[500px]">
          <SlidingBanner />
        </div>
      </div>
      <div>
        <Product item={products} categoryTitle="Best Sellers" refresh={null} />
      </div>
    </div>
  );
};

export default ProductCategory;
