import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { IconContext } from "react-icons";
import { fetchBlog } from "../Services/request";
import { BeatLoader, ClipLoader } from "react-spinners";
import Loader from "../Components/Loader/Loader";

const BlogStory = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [like, activeLike] = useState(false);
  // const [likeCount, setLikeCount] = useState(0);
  const [blogData, setBlogData] = useState([]);

  const fetchSingleBlog = async () => {
    setLoading(true);
    let data = await fetchBlog(blogId);
    if (data) {
      setBlogData(data?.data?.blog);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleBlog();
  }, []);

  // const Blog = blogData.find((Blogs) => blogId === Blogs.id);
  // console.log(Blog);

  //destructure the result
  // const { id, BlogCategory, topic, subtop, Image, count, body, Author, date, timeStamp } = Blog;

  // const setLike = () => {
  //   activeLike(!like);
  //   setLikeCount(1);
  // };
  return (
    <div className="my-[60px]">
      {loading ? (
        <div className="h-[300px]">
          <ClipLoader />
        </div>
      ) : (
        <div>
          <IconContext.Provider value={{ size: "35px" }}>
            <div onClick={() => navigate(-1)} className="my-5">
              <IoArrowBack />
            </div>
          </IconContext.Provider>

          <div className=" h-[500px] overflow-hidden bg-fixed rounded-xl relative flex justify-center ">
            <img src={blogData.image} className="object-cover rounded-xl  w-full h-full" />
          </div>

          <div className=" my-10">
            <div className="my-10">
              <h1 className="font-semibold text-[42px] mobile:text-[32px] mobile:text-center smtab:text-[36px] ">
                {blogData.title}
              </h1>
              <p className="text-[14px] mobile:text-center italic">{blogData.description}</p>
              <p className="flex flex-col mobile:flex-col">
                <span>{new Date(blogData.created_at).toDateString()}</span>
                <span className="text-accent"> Author : Rola</span>
              </p>
            </div>
            {/**Body */}
            <div className="my-[50px] leading-10">
              <div
                dangerouslySetInnerHTML={{ __html: blogData.blog }}
                className="[&>p>span]:text-lg [&>h1]:font-semibold [&>h1]:text-[24px] [&>p>img]:w-[100%] [&>p>img]:h-[300px] [&>p>img]:object-contain [&>img]:border-black [&>p]:flex [&>p]:my-5 [&>p]:flex-col"
              >
                {}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className=" flex items-center justify-center my-[50px]">
        <div className=" flex flex-col items-center justify-center">
          <p className="text-[18px] text-[gray]">Like this Blog?</p>
          <IconContext.Provider value={{ size: "24px", color: "#066B63" }}>
            <div onClick={setLike} className="my-5">
              {like ? <IoHeart /> : <IoHeartOutline />}
            </div>
          </IconContext.Provider>
        </div>
      </div> */}
    </div>
  );
};

export default BlogStory;
